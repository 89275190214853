<template>
  <a-spin :spinning="spinning" style="height:100%;background-color: rgb(217, 220, 224);">
    <div style="height:100%;overflow: hidden;">
      <div class="show-head">
        <div class="logo">
          <img :src="logo" @click="goToHome" alt="logo" />
          <span>文字识别</span>
        </div>
        <div class="show-head-left">
          <div>
            <a-icon
              type="left-circle"
              :class="pageNum <= 1 ? 'icon-color' : ''"
              @click="
                () => {
                  if (this.imgs.length > 0) {
                    this.pageNum > 1 && --this.pageNum
                  } else {
                    this.pageNum > 1 && --this.pageNum
                  }
                }
              "
            />
            <a-icon
              type="right-circle"
              style="margin-left:15px;"
              :class="pageNum == imgs.length ? 'icon-color' : ''"
              @click="
                () => {
                  if (this.imgs.length > 0) {
                    this.pageNum < this.imgs.length && ++this.pageNum
                  }
                }
              "
            />
          </div>
          <div style="margin-left:15px;">
            <input type="text" v-model="pageNum" class="fun-input-imgs" />
            <span style="margin-left:4px">/{{ this.imgs.length }}</span>
          </div>
          <div>
            <a-slider :min="10" id="test" @change="sliderChange" v-model="sliderValue" :disabled="false" />
          </div>
          <div style="margin-left:15px;">
            <span>
              切片高度：
            </span>
            <input type="text" v-model="fileInfoList[0].cutSize" class="fun-input" />
            <span style="margin-left:5px">PX</span>
          </div>
        </div>
        <div class="show-head-right">
          <div style="display: flex;">
            <button class="btn" @click="getCuts" style="font-size:0.7vw;margin:auto 0">切片</button>
            <button class="btn" @click="postCuts" style="font-size:0.7vw;margin:auto 0;margin-left:1vw;">OCR</button>
            <button class="btn" @click.stop="exportMenu" style="font-size:0.7vw;margin:auto 0;margin-left:1vw;">
              导出
            </button>
            <button class="btn" style="font-size:0.7vw;margin:auto 0;margin-left:1vw;" @click="cencel">清除</button>
            <button class="btn-layout" style="font-size:0.7vw;margin:auto 0;margin-left:1vw;" @click="handleLogout">
              退出
            </button>
          </div>
        </div>
      </div>
      <div class="show-body">
        <div class="show-body-left">
          <div class="name-head">
            <div class="name">
              <span>{{
                fileList.length > 0
                  ? `${fileList[pageNum - 1].name}${
                      imgSizeLeftList[fileList[pageNum - 1].name]
                        ? '（' +
                          imgSizeLeftList[fileList[pageNum - 1].name].imgWidth +
                          'x' +
                          imgSizeLeftList[fileList[pageNum - 1].name].imgHeight +
                          '像素，' +
                          imgSizeLeftList[fileList[pageNum - 1].name].size +
                          '）'
                        : ''
                    }`
                  : ''
              }}</span>
              <a-icon type="close" class="close-icon" />
            </div>
          </div>
          <div class="img-body" :style="'background-color:' + this.backGroundColor">
            <div class="show-img" @contextmenu.prevent="rightClick">
              <a-upload-dragger
                accept="image/*"
                v-if="imgs.length == 0"
                name="file"
                :showUploadList="false"
                :multiple="true"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <div>
                  <p class="ant-upload-drag-icon">
                    <a-icon type="folder-open" />
                  </p>
                  <p class="ant-upload-text">
                    将文件拖到此处，或点击该区域任意处打开
                  </p>
                  <p class="ant-upload-hint">
                    支持多张图片或单张图片上传
                  </p>
                </div>
              </a-upload-dragger>
              <div v-else class="img-list" :style="'width:100%'">
                <img
                  v-for="(item, i) in imgs"
                  :key="item"
                  :src="item"
                  :style="i == 0 ? 'width:' + sliderValue + '%' : 'width:' + sliderValue + '%;' + 'margin-top:8px'"
                />
                <div class="cut-list" v-if="cuts.length > 0">
                  <div v-for="(data, i) in cuts" :key="i" :style="getSize(data.imgHeight, i)">
                    <template v-if="data.heightList">
                      <div
                        v-for="(item, index) in data.heightList"
                        :key="index"
                        :style="`width:100%;height:${getHeight(item)}px;border-bottom: 2px solid red;`"
                      ></div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="show-body-right">
          <div class="name-head"></div>
          <div class="show-ocr">
            <div style="font-size:1vw;padding: 12px 17px;">OCR结果</div>
            <a-collapse v-if="cutText.length > 0" v-model="activeKey">
              <template v-for="(item, index) in cutText">
                <a-collapse-panel :key="index + ''" :header="item.filename" v-if="item.results != null">
                  <div
                    style="padding-left:24px;display:flex;padding-bottom:17px;z-index:998"
                    v-for="(text, i) in item.results"
                    :key="i"
                  >
                    <div style="width:30px;">{{ i + 1 }}</div>
                    <div style="">{{ text }}</div>
                  </div>
                </a-collapse-panel>
              </template>
            </a-collapse>
          </div>
          <table id="out-table">
            <tr v-for="(item, index) in tableData" :key="index">
              <td>{{ item.data }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
import { postAction, getAction } from '@/api/manage'
import { mapActions } from 'vuex'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      imgs: [],
      pageNum: 0,
      sliderValue: 100,
      backGroundColor: '#d9dce0',
      fileList: [],
      leftArr: [],
      imgSizeLeftList: [],
      fileInfoList: [{ cutSize: '2000' }],
      cuts: [],
      spinning: false,
      scale: 1,
      imgHeight: [],
      cutText: [],
      isUp: true,
      isDown: true,
      activeKey: [],
      tableData: [],
      heightList: [0],
      timeOut: 300,
      logo: '../../assets/favicon.png'
    }
  },
  watch: {
    pageNum(val) {
      if (this.isUp && this.isDown) {
        if (val > 0) {
          if (val <= this.imgs.length && document.getElementsByClassName('img-list')[0]?.childNodes) {
            if (this.heightList.length == 1) {
              let height = 20
              document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
                height = height + item.height
                if (height != 20) height = height + 8
                this.heightList.push(height + 20)
              })
              this.heightList.pop()
            }
            this.$nextTick(() => {
              if (
                document.getElementsByClassName('img-list')[0].lastChild.height <
                document.getElementsByClassName('show-img')[0].clientHeight
              ) {
                let num =
                  document.getElementsByClassName('show-img')[0].clientHeight -
                  document.getElementsByClassName('img-list')[0].lastChild.height
                this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num
              }
              document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[val - 1]
              return
            })
          }
          this.$nextTick(() => {
            if (
              document.getElementsByClassName('img-list')[0].lastChild.height <
              document.getElementsByClassName('show-img')[0].clientHeight
            ) {
              let num =
                document.getElementsByClassName('show-img')[0].clientHeight -
                document.getElementsByClassName('img-list')[0].lastChild.height
              this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num
            }
            document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[val - 1]
          })
        }
      }
      this.isUp = true
      this.isDown = true
    },
    imgs(val) {
      if (val.length > 0) {
        setTimeout(() => {
          if (this.heightList.length == 1) {
            let height = 0
            document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
              height = height + item.height
              if (height != 20) height = height + 8
              this.heightList.push(height)
            })
            this.heightList.pop()
          }
          this.$nextTick(() => {
            if (
              document.getElementsByClassName('img-list')[0].lastChild.height <
              document.getElementsByClassName('show-img')[0].clientHeight
            ) {
              let num =
                document.getElementsByClassName('show-img')[0].clientHeight -
                document.getElementsByClassName('img-list')[0].lastChild.height
              this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num
            }
            document.getElementsByClassName('show-img')[0].scrollTop = this.heightList[this.pageNum - 1]
          })
        }, 3000)
      }
    }
  },
  created() {
    this.getBackGround()
  },
  mounted() {
    document.getElementsByClassName('show-img')[0].addEventListener('scroll', this.handleScroll)
    this.timerFun()
  },
  methods: {
    ...mapActions(['Logout']),
    timerFun() {
      this.stopTimer = false
      let myTimer = setInterval(async () => {
        // 停止定时器
        if (this.stopTimer == true) {
          clearInterval(myTimer)
          return
        }
        await getAction('/sys/checkLogin')
      }, 6000)
    },
    goToHome() {
      window.location.href = '/'
    },
    async getBackGround() {
      try {
        const res = await getAction('/theme/query')
        if (res.msg == 'SUCCESSFUL') {
          res.data.forEach(item => {
            if (item.type == 'LOGO') {
              this.logo = item.value
            }
          })
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleLogout() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk() {
          return that
            .Logout({})
            .then(() => {
              // update-begin author:wangshuai date:20200601 for: 退出登录跳转登录页面
              let editorOrigin = Vue.ls.get('EDITOR_ORIGIN')
              if (editorOrigin) {
                try {
                  let getUrl = editorOrigin + '/sso/logout'
                  console.log(getUrl)
                  let iframe = document.createElement('iframe')
                  iframe.style = 'display: none'
                  iframe.src = getUrl
                  iframe.onload = function() {
                    /* window.location.href = toEditor; */
                    document.body.removeChild(iframe)
                  }
                  document.body.appendChild(iframe)
                  // axios.get(getUrl, {
                  //   withCredentials: true,
                  // }).then(data => {
                  // }).catch(e => {
                  //   console.error(e);
                  // });
                } catch (e) {}
              }
              that.$router.push({ path: '/user/login' })
              // update-end author:wangshuai date:20200601 for: 退出登录跳转登录页面
              //window.location.reload()
            })
            .catch(err => {
              that.$message.error({
                title: '错误',
                description: err.message
              })
            })
        },
        onCancel() {}
      })
    },
    handleScroll(e) {
      setTimeout(() => {
        if (e.target.scrollTop > this.heightList[this.pageNum]) {
          this.isDown = false
          this.pageNum++
        } else if (e.target.scrollTop < this.heightList[this.pageNum - 1] - 1 && this.pageNum > 1) {
          this.isUp = false
          this.pageNum--
        }
      }, 100)
    },
    exportMenu() {
      this.$contextmenu({
        items: [
          {
            label: '导出表格',
            onClick: () => {
              this.exportExcel()
            }
          },
          {
            label: '导出文本',
            onClick: () => {
              this.exportTxt()
            }
          }
        ],
        event,
        customClass: 'context-menu',
        zIndex: 1999,
        minWidth: 80
      })
    },
    exportExcel() {
      if (this.cutText.length > 0) {
        /* out-table关联导出的dom节点  */
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
        /* get binary string as output */
        var wbout = XLSX.write(wb, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        })
        var filename = this.cutText[0].filename.split('.')[0] + '.xlsx'
        try {
          FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), filename)
        } catch (e) {
          if (typeof console !== 'undefined') console.log(e, wbout)
        }
        this.$message.success('导出成功')
        return wbout
      } else {
        this.$message.error('请先进行OCR')
      }
    },
    //导出txt格式
    exportTxt() {
      if (this.cutText.length > 0) {
        var filename = '文件名称'
        //文件内容
        filename = this.cutText[0].filename.split('.')[0]
        var text = ''
        this.cutText.forEach(item => {
          text += item.filename.split('.')[0] + '\n'
          item.results.forEach(data => {
            text += data + '\n'
          })
        })
        var pom = document.createElement('a')
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        pom.setAttribute('download', filename)
        if (document.createEvent) {
          var event = document.createEvent('MouseEvents')
          event.initEvent('click', true, true)
          pom.dispatchEvent(event)
        } else {
          pom.click()
        }
        this.$message.success('导出成功')
      } else {
        this.$message.error('请先进行OCR')
      }
    },
    postCuts() {
      this.spinning = true
      if (this.imgs.length == 0) {
        this.$message.error('请先上传图片')
        this.spinning = false
        return
      }
      let formData = new FormData()
      let fileInfoList = []
      this.fileList.forEach((item, index) => {
        formData.append('fileList', item)
        fileInfoList.push({ fileName: item.name, cutSize: this.fileInfoList[0].cutSize })
      })
      fileInfoList.forEach((item, index) => {
        let nameKey = 'fileInfoList[' + index + '].filename'
        let cutSizeKey = 'fileInfoList[' + index + '].cutSize'
        formData.append(nameKey, item.fileName)
        formData.append(cutSizeKey, item.cutSize)
      })
      postAction('/ocr/ocr', formData)
        .then(res => {
          this.cutText = res.data
          this.activeKey = []
          this.cutText.forEach((item, i) => {
            this.activeKey.push(i + '')
            this.tableData.push({ data: item.filename })
            item.results.forEach(data => {
              this.tableData.push({ data })
            })
          })
          this.spinning = false
          if (res.success) {
            this.$message.success(res.message || res.msg)
          } else {
            this.$message.error(res.message || res.msg)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    getHeight(item) {
      let num = this.sliderValue / 100
      return item * num
    },
    getCuts() {
      this.cuts = []
      this.imgHeight = []
      document.getElementsByClassName('img-list')[0].childNodes.forEach(item => {
        if (item.width) {
          let heightList = []
          item.style.width = 'auto'
          let itemWidth = item.width
          let itemHeigt = item.height
          item.style.width = this.sliderValue + '%'
          this.scale = itemWidth / item.width
          this.scale = this.scale.toFixed(2)
          let num = 0
          num = item.height / this.fileInfoList[0].cutSize
          if (this.scale > 1) {
            num = num * this.scale
          } else {
            num = num / this.scale
          }
          num = num + ''
          num = num.split('.')[0]
          if (this.scale > 1) {
            for (let i = 0; i < num; i++) {
              let height = this.fileInfoList[0].cutSize / this.scale
              if (this.isGet) {
                let num = this.sliderValue / 100
                height = height / num
              }
              heightList.push(height)
            }
          } else {
            for (let i = 0; i < num; i++) {
              let height = this.fileInfoList[0].cutSize * this.scale
              if (this.isGet) {
                let num = this.sliderValue / 100
                height = height / num
              }
              heightList.push(height)
            }
          }
          this.imgHeight.push({ height: item.height })
          this.cuts.push({ heightList: heightList, imgHeight: itemHeigt })
        }
      })
      this.isGet = true
    },
    getSize(height, i) {
      let text = i != 0 ? 'margin-top:8px;' : ''
      return (
        'height:' +
        this.imgHeight[i].height +
        'px;width:' +
        this.sliderValue +
        '%;position: relative;margin: 0 auto;' +
        text
      )
    },
    sliderChange() {
      setTimeout(() => {
        let height = 20
        this.heightList = [0]
        document.getElementsByClassName('img-list')[0].childNodes.forEach((item, index) => {
          this.imgHeight.forEach((data, i) => {
            if (index == i) {
              data.height = item.height
            }
          })
          height = height + item.height
          if (height != 20) height = height + 8
          this.heightList.push(height)
        })
        this.heightList.pop()
        if (
          document.getElementsByClassName('img-list')[0].lastChild.height <
          document.getElementsByClassName('show-img')[0].clientHeight
        ) {
          let num =
            document.getElementsByClassName('show-img')[0].clientHeight -
            document.getElementsByClassName('img-list')[0].lastChild.height
          this.heightList[this.heightList.length - 1] = this.heightList[this.heightList.length - 1] - num
        }
      }, 100)
    },
    cencel() {
      let height = this.fileInfoList[0].cutSize
      let sliderValue = this.sliderValue
      let isGet = this.isGet
      Object.assign(this._data, this.$options.data())
      this.fileInfoList[0].cutSize = height
      this.sliderValue = sliderValue
      this.isGet = isGet
    },
    rightClick() {
      this.$contextmenu({
        items: [
          {
            label: '黑色',
            onClick: () => {
              this.backGroundColor = 'black'
            }
          },
          {
            label: '白色',
            onClick: () => {
              this.backGroundColor = '#fff'
            }
          },
          {
            label: '灰色',
            onClick: () => {
              this.backGroundColor = '#d9dce0'
            }
          }
        ],
        event,
        customClass: 'resource-context-menu',
        zIndex: 999,
        minWidth: 80
      })
    },
    handleChange(info) {
      this.leftArr.push(info)
      if (this.leftArr.length == info.fileList.length) {
        /*         this.fileList.forEach(a => {
          if (a.name.split('.')[0][a.name.split('.')[0].length - 1] - 0 + '' == 'NaN') {
            this.$message.error(a.name + '文件名不是以数字结尾')
          }
        }) */
        this.leftArr.forEach((item, i) => {
          this.fileList.forEach((data, index) => {
            if (i == index) {
              const url = window.URL ? window.URL.createObjectURL(data) : window.webkitURL.createObjectURL(data)
              item.url = url
              let reader = new FileReader()
              reader.readAsDataURL(data)
              reader.onload = async () => {
                const image = new Image()
                image.src = reader.data
                image.onload = () => {
                  item.imgWidth = image.width
                  item.imgHeight = image.height
                  let obj = {}
                  let imgSize = (item.file.size / 1024 / 1024).toFixed(2) + 'M'
                  console.log(item.imgHeight, 456)
                  obj[item.file.name] = { imgWidth: item.imgWidth, imgHeight: item.imgHeight, size: imgSize }
                  this.imgSizeLeftList = Object.assign({}, this.imgSizeLeftList, obj)
                }
              }
            }
          })
        })
        this.leftArr.forEach(item => {
          this.imgs.push(item.url)
        })
        this.pageNum = 1
      }
    },
    beforeUpload(file, e) {
      this.fileList = [...this.fileList, file]
      /*       this.fileList.sort((a, b) => {
        let result = a.name.match(/(\d+)[^0-9]*$/)[1]
        let resultB = b.name.match(/(\d+)[^0-9]*$/)[1]
        return result - resultB
      }) */
      return false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 45%;
}
/deep/ .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .ant-collapse-content {
  border: none !important;
}
.ant-collapse-item {
  border: none;
  background-color: #fff;
}
.ant-collapse {
  border: none;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 7px 16px;
  padding-bottom: 14px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
/deep/ .ant-spin-nested-loading {
  height: 100% !important;
}
/deep/ .ant-spin-container {
  height: 100% !important;
}
.show-head {
  height: 5%;
  min-height: 40px;
  background-color: #f1f3f6;
  border-bottom: 1px solid #bdbdbd;
  justify-content: center;
  display: flex;
  .logo {
    position: absolute;
    left: 24px;
    top: 6px;
    img {
      height: 32px;
      min-width: 32px;
      position: relative;
      top: -3px;
      cursor: pointer;
    }
    span {
      margin-left: 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .show-head-left {
    width: 38vw;
    height: 100%;
    min-width: 350px;
    display: flex;
    font-size: 0.8vw;
    justify-content: center;
    align-items: center;
    > div {
      line-height: 1;
      min-width: 50px;
    }
    .icon-color {
      color: rgb(161, 163, 168);
    }
    .fun-input {
      display: inline-block;
      width: 3vw;
      height: 26px;
      line-height: 1;
      background: #fff;
      text-align: center;
      border: none;
    }
    .fun-input-imgs {
      display: inline-block;
      width: 1.5vw;
      height: 26px;
      line-height: 1;
      background: #fff;
      text-align: center;
      border: none;
    }
  }
  .show-head-right {
    width: 38vw;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      background-color: #fff;
      border: 1px solid #c1c1c1;
      height: 26px;
      min-width: 40px;
      padding-left: 1vw;
      padding-right: 1vw;
      border-radius: 3px;
      line-height: 1;
      &:hover {
        background-color: #f1f3f4;
        cursor: pointer;
      }
    }
    .btn-layout {
      background-color: #fff;
      border: 1px solid #c1c1c1;
      height: 26px;
      min-width: 40px;
      padding-left: 1vw;
      padding-right: 1vw;
      border-radius: 3px;
      line-height: 1;
      position: absolute;
      right: 1.5vw;
      &:hover {
        background-color: #f1f3f4;
        cursor: pointer;
      }
    }
  }
  .ant-slider {
    width: 7vw;
    margin-top: 12px;
    margin-left: 15px;
  }
  /deep/ .ant-slider-rail {
    background-color: #e1e1e1 !important;
  }
  /deep/ .ant-slider-track {
    background-color: #a1a3a8 !important;
  }
  /deep/ .ant-slider-handle:focus {
    border-color: #a1a3a8;
    outline: none;
    box-shadow: unset !important;
  }
  /deep/ .ant-slider-handle:hover {
    border-color: #6d6e72;
    outline: none;
    box-shadow: 0 0 0 0px rgb(83 137 223 / 20%) !important;
  }
  /deep/ .ant-slider-handle {
    border: 2px solid #a1a3a8;
  }
}
.show-body {
  height: 100%;
  background-color: #f1f3f6;
  padding-left: 12vw;
  padding-right: 12vw;
  display: flex;
  .show-body-left {
    width: 38vw;
    height: 95%;
    background-color: #d9dce0;
    position: relative;
    border-left: 1px solid #bdbdbd;
    .name-head {
      width: 100%;
      height: 4%;
      min-height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #bdbdbd;
      .name {
        font-size: 14px;
        padding-left: 8px;
      }
      .close-icon {
        position: absolute;
        right: 8px;
        font-size: 12px;
        top: 8px;
      }
    }
    .img-body {
      height: 96%;
      box-sizing: border-box;
      .show-img {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid #bcc1c5;
        text-align: center;
        overflow-y: scroll;
        overflow: overlay;
        position: relative;
        .img-list {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 8px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 998;
          img {
            width: 100%;
            display: block;
            margin: 0 auto;
          }
          .cut-list {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100% !important;
            padding-right: 8px;
            > div {
              position: relative;
              border: 2px solid red;
            }
          }
        }
        > span {
          width: 100%;
          height: 100%;
          border: none;
          /deep/ .ant-upload.ant-upload-drag {
            background: rgba(0, 0, 0, 0) !important;
            border: none;
            &:hover {
              border: none;
            }
          }
          /deep/ .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
            color: rgba(0, 0, 0, 0.25) !important;
          }
        }
      }
    }
  }
  .show-body-right {
    width: 38vw;
    height: 95%;
    background-color: #fff;
    border-left: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    .name-head {
      width: 100%;
      height: 4%;
      min-height: 30px;
    }
    .show-ocr {
      height: 96%;
      padding-left: 3vw;
      padding-right: 3.5vw;
      box-sizing: border-box;
      overflow-y: scroll;
      overflow: overlay;
    }
  }
  ::-webkit-scrollbar-button {
    height: 0;
  }
  ::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
}
</style>
