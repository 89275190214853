var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticStyle: { height: "100%", "background-color": "rgb(217, 220, 224)" },
      attrs: { spinning: _vm.spinning },
    },
    [
      _c("div", { staticStyle: { height: "100%", overflow: "hidden" } }, [
        _c("div", { staticClass: "show-head" }, [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              attrs: { src: _vm.logo, alt: "logo" },
              on: { click: _vm.goToHome },
            }),
            _c("span", [_vm._v("文字识别")]),
          ]),
          _c("div", { staticClass: "show-head-left" }, [
            _c(
              "div",
              [
                _c("a-icon", {
                  class: _vm.pageNum <= 1 ? "icon-color" : "",
                  attrs: { type: "left-circle" },
                  on: {
                    click: () => {
                      if (this.imgs.length > 0) {
                        this.pageNum > 1 && --this.pageNum
                      } else {
                        this.pageNum > 1 && --this.pageNum
                      }
                    },
                  },
                }),
                _c("a-icon", {
                  class: _vm.pageNum == _vm.imgs.length ? "icon-color" : "",
                  staticStyle: { "margin-left": "15px" },
                  attrs: { type: "right-circle" },
                  on: {
                    click: () => {
                      if (this.imgs.length > 0) {
                        this.pageNum < this.imgs.length && ++this.pageNum
                      }
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-left": "15px" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pageNum,
                    expression: "pageNum",
                  },
                ],
                staticClass: "fun-input-imgs",
                attrs: { type: "text" },
                domProps: { value: _vm.pageNum },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.pageNum = $event.target.value
                  },
                },
              }),
              _c("span", { staticStyle: { "margin-left": "4px" } }, [
                _vm._v("/" + _vm._s(this.imgs.length)),
              ]),
            ]),
            _c(
              "div",
              [
                _c("a-slider", {
                  attrs: { min: 10, id: "test", disabled: false },
                  on: { change: _vm.sliderChange },
                  model: {
                    value: _vm.sliderValue,
                    callback: function ($$v) {
                      _vm.sliderValue = $$v
                    },
                    expression: "sliderValue",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-left": "15px" } }, [
              _c("span", [_vm._v("\n            切片高度：\n          ")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fileInfoList[0].cutSize,
                    expression: "fileInfoList[0].cutSize",
                  },
                ],
                staticClass: "fun-input",
                attrs: { type: "text" },
                domProps: { value: _vm.fileInfoList[0].cutSize },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.fileInfoList[0],
                      "cutSize",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v("PX"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "show-head-right" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: { "font-size": "0.7vw", margin: "auto 0" },
                  on: { click: _vm.getCuts },
                },
                [_vm._v("切片")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: {
                    "font-size": "0.7vw",
                    margin: "auto 0",
                    "margin-left": "1vw",
                  },
                  on: { click: _vm.postCuts },
                },
                [_vm._v("OCR")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: {
                    "font-size": "0.7vw",
                    margin: "auto 0",
                    "margin-left": "1vw",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.exportMenu.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            导出\n          ")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: {
                    "font-size": "0.7vw",
                    margin: "auto 0",
                    "margin-left": "1vw",
                  },
                  on: { click: _vm.cencel },
                },
                [_vm._v("清除")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn-layout",
                  staticStyle: {
                    "font-size": "0.7vw",
                    margin: "auto 0",
                    "margin-left": "1vw",
                  },
                  on: { click: _vm.handleLogout },
                },
                [_vm._v("\n            退出\n          ")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "show-body" }, [
          _c("div", { staticClass: "show-body-left" }, [
            _c("div", { staticClass: "name-head" }, [
              _c(
                "div",
                { staticClass: "name" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.fileList.length > 0
                          ? `${_vm.fileList[_vm.pageNum - 1].name}${
                              _vm.imgSizeLeftList[
                                _vm.fileList[_vm.pageNum - 1].name
                              ]
                                ? "（" +
                                  _vm.imgSizeLeftList[
                                    _vm.fileList[_vm.pageNum - 1].name
                                  ].imgWidth +
                                  "x" +
                                  _vm.imgSizeLeftList[
                                    _vm.fileList[_vm.pageNum - 1].name
                                  ].imgHeight +
                                  "像素，" +
                                  _vm.imgSizeLeftList[
                                    _vm.fileList[_vm.pageNum - 1].name
                                  ].size +
                                  "）"
                                : ""
                            }`
                          : ""
                      )
                    ),
                  ]),
                  _c("a-icon", {
                    staticClass: "close-icon",
                    attrs: { type: "close" },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "img-body",
                style: "background-color:" + this.backGroundColor,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "show-img",
                    on: {
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.rightClick.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.imgs.length == 0
                      ? _c(
                          "a-upload-dragger",
                          {
                            attrs: {
                              accept: "image/*",
                              name: "file",
                              showUploadList: false,
                              multiple: true,
                              "before-upload": _vm.beforeUpload,
                            },
                            on: { change: _vm.handleChange },
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                { staticClass: "ant-upload-drag-icon" },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "folder-open" },
                                  }),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "ant-upload-text" }, [
                                _vm._v(
                                  "\n                  将文件拖到此处，或点击该区域任意处打开\n                "
                                ),
                              ]),
                              _c("p", { staticClass: "ant-upload-hint" }, [
                                _vm._v(
                                  "\n                  支持多张图片或单张图片上传\n                "
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "img-list", style: "width:100%" },
                          [
                            _vm._l(_vm.imgs, function (item, i) {
                              return _c("img", {
                                key: item,
                                style:
                                  i == 0
                                    ? "width:" + _vm.sliderValue + "%"
                                    : "width:" +
                                      _vm.sliderValue +
                                      "%;" +
                                      "margin-top:8px",
                                attrs: { src: item },
                              })
                            }),
                            _vm.cuts.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "cut-list" },
                                  _vm._l(_vm.cuts, function (data, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        style: _vm.getSize(data.imgHeight, i),
                                      },
                                      [
                                        data.heightList
                                          ? _vm._l(
                                              data.heightList,
                                              function (item, index) {
                                                return _c("div", {
                                                  key: index,
                                                  style: `width:100%;height:${_vm.getHeight(
                                                    item
                                                  )}px;border-bottom: 2px solid red;`,
                                                })
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "show-body-right" }, [
            _c("div", { staticClass: "name-head" }),
            _c(
              "div",
              { staticClass: "show-ocr" },
              [
                _c(
                  "div",
                  { staticStyle: { "font-size": "1vw", padding: "12px 17px" } },
                  [_vm._v("OCR结果")]
                ),
                _vm.cutText.length > 0
                  ? _c(
                      "a-collapse",
                      {
                        model: {
                          value: _vm.activeKey,
                          callback: function ($$v) {
                            _vm.activeKey = $$v
                          },
                          expression: "activeKey",
                        },
                      },
                      [
                        _vm._l(_vm.cutText, function (item, index) {
                          return [
                            item.results != null
                              ? _c(
                                  "a-collapse-panel",
                                  {
                                    key: index + "",
                                    attrs: { header: item.filename },
                                  },
                                  _vm._l(item.results, function (text, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticStyle: {
                                          "padding-left": "24px",
                                          display: "flex",
                                          "padding-bottom": "17px",
                                          "z-index": "998",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "30px" } },
                                          [_vm._v(_vm._s(i + 1))]
                                        ),
                                        _c("div", {}, [_vm._v(_vm._s(text))]),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "table",
              { attrs: { id: "out-table" } },
              _vm._l(_vm.tableData, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(item.data))]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }